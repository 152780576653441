import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Justin Bieber',
    useCase: 'FASHION DESIGN, CREATIVE DIRECTION',
    description: `I worked with Justin Bieber back in 2011 - 2013.
      Working on Justin’s Christmas Tour appearances provided many challenges. From performing at the AMA’s , Bambi Award’s to his music video with Mariah Carey it was a fast paced experience that provided a lot of value to me and my growth as a designer and creative director.`,
  },
  caseId: 'justin-bieber',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    {
      youtube: {
        src: 'https://www.youtube-nocookie.com/embed/fGFNmEOntFA',
      },
    },
    // 'image4.jpg',
    'image5.jpg',
    'image6.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
